import * as flatbuffers from "flatbuffers";

import type { Bridge } from "@/feature-bridge/bridge.mjs";
import type { IConnection } from "@/feature-bridge/connection.mjs";
import * as idl from "@/feature-bridge/idl/rpc_message/rpc_message.mjs";

type ToBytes<T> = { toBytes: (input: T) => Uint8Array };
type FromBytes<T> = { fromBytes: (bytes: Uint8Array) => T };

type Converter<T> = (ToBytes<T> & FromBytes<T>) | ToBytes<T> | FromBytes<T>;

type RpcHandler<Params, Result> = (
  connection: IConnection,
  params: Params,
) => Promise<Result> | Result;

export type BridgeRpcChannelArgs<Params, Result> = {
  name: string;

  params: Converter<Params>;
  result: Converter<Result>;

  handler: RpcHandler<Params, Result>;
  authorized?: boolean;
};

export class BridgeRpcChannel<Params, Result> {
  #name: string;
  #params: Converter<Params>;
  #result: Converter<Result>;
  #handler: RpcHandler<Params, Result>;

  #correlationId = 0;

  #waitingFor = new Map<number, (result: Uint8Array) => void>();

  constructor(
    bridge: Bridge,
    {
      name,
      params,
      result,
      handler,
      authorized,
    }: BridgeRpcChannelArgs<Params, Result>,
  ) {
    this.#name = name;
    this.#params = params;
    this.#result = result;
    this.#handler = handler;

    bridge.on(
      authorized ?? true ? "authorizedMessage" : "unauthorizedMessage",
      ({ connection, channel, data }) => {
        if (channel !== this.#name) return;

        this.#onReceiveMessage(connection, data);
      },
    );
  }

  get name() {
    return this.#name;
  }

  async call(connection: IConnection, params: Params) {
    if (!("toBytes" in this.#params)) {
      throw new Error("Cannot call RPC channel without parameter converter");
    }

    if (!("fromBytes" in this.#result)) {
      throw new Error("Cannot call RPC channel without result converter");
    }

    const correlationId = this.#correlationId++;

    const builder = new flatbuffers.Builder();

    builder.finish(
      idl.RpcMessage.createRpcMessage(
        builder,
        idl.RpcBody.Request,
        idl.RpcRequest.createRpcRequest(
          builder,
          correlationId,
          idl.RpcRequest.createParamsVector(
            builder,
            this.#params.toBytes(params),
          ),
        ),
      ),
    );

    await connection.send(this.#name, builder.asUint8Array());

    const result = await new Promise<Uint8Array>((resolve) => {
      this.#waitingFor.set(correlationId, resolve);
    });

    return this.#result.fromBytes(result);
  }

  async #onReceiveMessage(connection: IConnection, data: Uint8Array) {
    if (!("fromBytes" in this.#params)) {
      throw new Error("Cannot handle RPC message without parameter converter");
    }

    if (!("toBytes" in this.#result)) {
      throw new Error("Cannot handle RPC message without result converter");
    }

    const rpc = idl.RpcMessage.getRootAsRpcMessage(
      new flatbuffers.ByteBuffer(data),
    );

    const body = idl.unionToRpcBody(rpc.bodyType(), (...args) => {
      return rpc.body(...args);
    });

    if (body instanceof idl.RpcRequest) {
      const result = await this.#handler(
        connection,
        this.#params.fromBytes(body.paramsArray()),
      );

      if (!connection.isReady) return;

      const builder = new flatbuffers.Builder();

      builder.finish(
        idl.RpcMessage.createRpcMessage(
          builder,
          idl.RpcBody.Response,
          idl.RpcResponse.createRpcResponse(
            builder,
            body.correlationId(),
            idl.RpcRequest.createParamsVector(
              builder,
              this.#result.toBytes(result),
            ),
          ),
        ),
      );

      connection.send(this.#name, builder.asUint8Array());
    } else if (body instanceof idl.RpcResponse) {
      const resolver = this.#waitingFor.get(body.correlationId());

      if (resolver) {
        this.#waitingFor.delete(body.correlationId());

        resolver(body.resultArray());
      }
    }
  }
}
